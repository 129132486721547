'use client'

import { Cell, Text, Icon } from '@vinted/web-ui'
import { Box32 } from '@vinted/multichrome-icons'
import { useIntl } from 'react-intl'

import useTranslate from 'hooks/useTranslate'
import { EscrowShippingFeeModel } from 'types/models/escrow-fees'
import { formatCurrencyAmount } from 'libs/utils/formatString'

type Props = {
  shippingFee: EscrowShippingFeeModel
  isItemOwner: boolean
}

const ShippingDetails = ({ shippingFee, isItemOwner }: Props) => {
  const translate = useTranslate('item_price_breakdown_detailed.shipping_info')
  const { locale } = useIntl()

  const hasDiscount = !!shippingFee?.maxDiscountPercentage
  const shouldShowDiscount = !isItemOwner && hasDiscount

  if (shippingFee) {
    const renderTitle = () => (
      <div className="u-flexbox u-align-items-center u-gap-small">
        {translate('title')}
        {shouldShowDiscount && (
          <Text
            testId="shipping-details-discount-amount"
            theme="warning"
            text={`${translate('percentage_discount', {
              discount: shippingFee.maxDiscountPercentage,
            })}`}
          />
        )}
      </div>
    )

    return (
      <Cell
        testId="item-price-breakdown-shipping-note"
        styling={Cell.Styling.Narrow}
        prefix={<Icon name={Box32} />}
        title={renderTitle()}
        body={
          <div>
            <div>
              <Text
                type={Text.Type.Title}
                theme="amplified"
                text={`${translate('from_price', {
                  price: formatCurrencyAmount(shippingFee.finalPrice, locale),
                })}`}
              />
            </div>
            <div>
              <Text text={translate('text')} type={Text.Type.Subtitle} theme="muted" />
            </div>
          </div>
        }
      />
    )
  }

  return (
    <Cell
      testId="item-price-breakdown-static-note"
      styling={Cell.Styling.Narrow}
      prefix={
        <Icon
          name={Box32}
          aria={{
            'aria-hidden': 'true',
          }}
        />
      }
      title={translate('title')}
      body={<Text text={translate('text')} type={Text.Type.Subtitle} theme="muted" />}
    />
  )
}

export default ShippingDetails
